import { IS_BROWSER } from "$fresh/runtime.ts"
import { signal } from "@preact/signals"

export enum ThemeType {
  System,
  Dark,
  Light,
}

/** Whether the current theme state is dark. */
export function isDark(): boolean {
  if (!IS_BROWSER) return false
  else {return localStorage.theme === "dark" ||
      (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)}
}

/** Return System if no user setting, otherwise return the setting type. */
export function getThemeType(): ThemeType {
  if (!IS_BROWSER || !("theme" in localStorage)) return ThemeType.System
  else return localStorage.theme === "dark" ? ThemeType.Dark : ThemeType.Light
}

type Theme = {
  dark: boolean
  type: ThemeType
}

// default theme
export const defaultTheme: Theme = { dark: isDark(), type: getThemeType() }

// theme state
export default signal(defaultTheme)
